/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://unsplash.com/ko/%EC%82%AC%EC%A7%84/40XgDxBfYXM"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.cdnetworks.com/ko/"
  }, "CDNetworks")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.kr.freebsd.org/"
  }, "한국 FreeBSD 사용자 그룹")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/freebsdkorea"
  }, "한국 FreeBSD 사용자 그룹 - KFUG (Facebook)")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://discord.gg/cBnSHPBpGf"
  }, "KFUG 디스코드 초대 링크")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://neumob.com/"
  }, "Neumob has joined Cloudflare!")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/ko-kr/"
  }, "Cloudflare")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://1.1.1.1/"
  }, "Cloudflare 1.1.1.1")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/"
  }, "Cloudflare Blog")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%BD%98%ED%85%90%EC%B8%A0_%EC%A0%84%EC%86%A1_%EB%84%A4%ED%8A%B8%EC%9B%8C%ED%81%AC"
  }, "CDN - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/bandwidth-costs-around-the-world/"
  }, "Cloudflare - Bandwidth Costs Around the World")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/ko-kr/bandwidth-alliance/"
  }, "Cloudflare - Bandwidth Alliance")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/aws-egregious-egress/"
  }, "Cloudflare - AWS’s Egregious Egress")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/welcome-to-the-supercloud-and-developer-week-2022/"
  }, "Cloudflare - Welcome to the Supercloud")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.cloudflare.com/ko-kr/learning/performance/what-is-http3/"
  }, "Cloudflare -HTTP/3란?")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.chromium.org/spdy/spdy-whitepaper/"
  }, "SPDY: An experimental protocol for a faster web")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://calendar.perfplanet.com/2020/head-of-line-blocking-in-quic-and-http-3-the-details/"
  }, "Head-of-Line Blocking in QUIC and HTTP/3: The Details")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/QUIC"
  }, "QUIC - Wikipedia")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.ietf.org/?pk_campaign=ietf-google-ads&pk_source=google&pk_medium=cpc&pk_content=ietf-brand&gclid=Cj0KCQjwmtGjBhDhARIsAEqfDEeZ26WV0b6mFZhCw-T80-QpDwUU-nLQ-lOyZDXkYR9XmEKSqoAPB4YaAhwSEALw_wcB"
  }, "IETF")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://http3-explained.haxx.se/ko/the-protocol/feature-udp"
  }, "HTTP/3 UDP")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://http3-explained.haxx.se/ko/h3/h3-altsvc"
  }, "Alt-svc로 부트스트랩하기")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://radar.cloudflare.com/adoption-and-usage/kr"
  }, "Cloudflare Radar - South Korea")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events"
  }, "Using server-sent events")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/early-hints/"
  }, "Early Hints: How Cloudflare Can Improve Website Load Times by 30%")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/early-hints-performance/"
  }, "Early Hints update: How Cloudflare, Google, and Shopify are working together to build a faster Internet for everyone")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/cloudflare/quiche"
  }, "HTTP/3 / QUIC 개발")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/cubic-and-hystart-support-in-quiche/"
  }, "CUBIC and HyStart++ Support in quiche")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://datatracker.ietf.org/doc/draft-eggert-tcpm-rfc8312bis/"
  }, "CUBIC for Fast and Long-Distance Networks")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
